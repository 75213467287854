<template>
  <div class="fault-content-1">
    <div class="content-1 display-flex">
      <div class="left">
        <div class="display-flex texts">故障诊断</div>
        <div class="display-flex textsub">局放诊断（xx位置）</div>
        <div class="display-flex subt">
          <div style="flex: 1">PRPD谱图诊断</div>
          <div style="flex: 1">阈值诊断</div>
        </div>
        <div class="display-flex">
          <div class="chart">
            <chart
              :cdata="prpdOptions"
              :id="'prpd_tu'"
              :height="'calc(15vh)'"
            />
          </div>
          <div class="chart">
            <chart
              :cdata="jf_yuzhi_options"
              :id="'prpd_yuzhi'"
              :height="'calc(15vh)'"
            />
          </div>
        </div>
        <div class="display-flex subt">趋势诊断</div>
        <div class="display-flex">
          <div class="chart-qushi">
            <chart
              :cdata="jf_qushi_options"
              :id="'jf_qushi'"
              :height="'calc(15vh)'"
            />
          </div>
        </div>
        <div class="display-flex result">诊断结果： 正常</div>
        <div class="display-flex textsub">温度诊断（xx位置）</div>
        <div class="display-flex subt">阈值诊断</div>
        <div class="display-flex">
          <div class="chart-qushi">
            <chart
              :cdata="wd_yuzhi_options"
              :id="'wd_yuzhi'"
              :height="'calc(15vh)'"
            />
          </div>
        </div>
        <div class="display-flex subt">趋势诊断</div>
        <div class="display-flex">
          <div class="chart-qushi">
            <chart
              :cdata="wd_qushi_options"
              :id="'wd_qushi'"
              :height="'calc(15vh)'"
            />
          </div>
        </div>
        <div class="display-flex result">诊断结果： 过热</div>
      </div>
      <div class="center">
        <div class="c-1">
          <img src="../image/byq-guzhang.png" alt="" class="responsive-image" />
          <div class="status position1">
            <div class="circle"></div>
            <div class="text">过热</div>
          </div>
          <div class="status position2">
            <div class="circle"></div>
            <div class="text">绕组短路</div>
          </div>
          <div class="status position3">
            <div class="circle"></div>
            <div class="text">绝缘老化</div>
          </div>
        </div>
        <div class="c-2">
          <div class="display-flex textsub">振动诊断</div>
          <div class="display-flex subt">阈值诊断</div>
          <div class="display-flex">
            <div class="chart-qushi">
              <chart
                :cdata="zd_yuzhi_options"
                :id="'zd_yuzhi'"
                :height="'calc(15vh)'"
              />
            </div>
          </div>
          <div class="display-flex subt">趋势诊断</div>
          <div class="display-flex">
            <div class="chart-qushi">
              <chart
                :cdata="zd_qushi_options"
                :id="'wd_qushi'"
                :height="'calc(15vh)'"
              />
            </div>
          </div>
          <div class="display-flex result">诊断结果： 正常</div>
        </div>
      </div>
      <div class="right">
        <div class="r-1">
          <div class="display-flex texts">故障原因分析</div>
          <div class="display-flex textsub">可能导致故障的原因</div>
          <div class="display-flex content-text">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如绝缘老化、机械磨损、连接松动
          </div>
          <div class="display-flex">
            <div style="flex: 1">
              <img
                src="../image/anlitu2.png"
                alt=""
                class="responsive-image1"
              />
            </div>
            <div style="flex: 1">
              <img src="../image/anlitu.png" alt="" class="responsive-image1" />
            </div>
          </div>
        </div>
        <div class="r-2">
          <div class="display-flex texts">故障案例推荐</div>
          <div class="display-flex textsub">案例描述</div>
          <div class="display-flex content-text">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在某电力变压器的高压绕组中，发生了一起高能放电引发的局部放电故障。经过检查，工程师们发现，绕组内部存在一处局部绝缘损伤，可能是由于外部环境因素或绝缘老化导致的。当电压施加到变压器绕组上时，由于局部绝缘损伤处的电场强度较高，导致了局部放电的发生。这种局部放电不仅会导致绝缘材料的进一步损伤，还会产生大量的气体，严重影响变压器的正常运行。为了解决这个问题，工程师们首先对绕组进行了局部维修，修复了绝缘损伤处。同时，他们还对变压器的绝缘系统进行了全面检查，确保没有其他潜在的局部放电隐患。
          </div>
          <div class="display-flex textsub">案例分析</div>
          <div class="display-flex content-text">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;高能放电引起气体生成量显著增加。
          </div>
          <div class="display-flex textsub">维修建议</div>
          <div class="display-flex content-text">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对绕组进行全面维护，查找放电源并修复。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "./echarts.vue";
export default {
  components: { chart },
  data() {
    return {
      prpdOptions: {},
      zd_yuzhi_options: {},
      wd_yuzhi_options: {},
      jf_yuzhi_options: {},
      jf_qushi_options: {},
      zd_qushi_options: {},
      wd_qushi_options: {},
    };
  },
  methods: {
    setData() {
      this.setZdYZOptions();
      this.setWdYZOptions();
      this.setJFYZOptions();
      this.setJFQSOptions();
      this.setWdQSOptions();
      this.setZdQSOptions();
      this.setPrpdOptions();
    },
    setZdYZOptions() {
      this.zd_yuzhi_options = {
        background: "transparent",
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          top: "5%",
          left: "5%",
          right: "15%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["A相", "B相", "C相"],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            lineStyle: {
              normal: {
                color: "#00b3f4",
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
          },
        ],
        series: [
          {
            name: "最大振幅(单位)",
            type: "bar",
            barWidth: "13",
            data: [110, 332, 200],
            markLine: {
              data: [
                {
                  yAxis: 40, // 自定义的阈值
                  lineStyle: {
                    color: "red", // 线的颜色
                    width: 2, // 线的宽度
                  },
                  label: {
                    show: true, // 显示标签
                    position: "end", // 标签的位置
                    formatter: "阈值线: {c}", // 自定义标签内容，{c}表示线的y值
                  },
                },
              ],
            },
          },
        ],
      };
    },
    setWdYZOptions() {
      this.wd_yuzhi_options = {
        background: "transparent",
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          top: "5%",
          left: "5%",
          right: "15%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["A相", "B相", "C相"],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            lineStyle: {
              normal: {
                color: "#00b3f4",
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
          },
        ],
        series: [
          {
            name: "温度值(℃)",
            type: "bar",
            barWidth: "13",
            data: [110, 332, 200],
            markLine: {
              data: [
                {
                  yAxis: 40, // 自定义的阈值
                  lineStyle: {
                    color: "red", // 线的颜色
                    width: 2, // 线的宽度
                  },
                  label: {
                    show: true, // 显示标签
                    position: "end", // 标签的位置
                    formatter: "阈值线: {c}℃", // 自定义标签内容，{c}表示线的y值
                  },
                },
              ],
            },
          },
        ],
      };
    },
    setJFYZOptions() {
      this.jf_yuzhi_options = {
        background: "transparent",
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          top: "5%",
          left: "5%",
          right: "15%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["A相", "B相", "C相"],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            lineStyle: {
              normal: {
                color: "#00b3f4",
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
          },
        ],
        series: [
          {
            name: "最大放电量幅值(dB)",
            type: "bar",
            barWidth: "13",
            data: [110, 332, 200],
            markLine: {
              data: [
                {
                  yAxis: 40, // 自定义的阈值
                  lineStyle: {
                    color: "red", // 线的颜色
                    width: 2, // 线的宽度
                  },
                  label: {
                    show: true, // 显示标签
                    position: "end", // 标签的位置
                    formatter: "阈值线: {c}dB", // 自定义标签内容，{c}表示线的y值
                  },
                },
              ],
            },
          },
        ],
      };
    },
    setJFQSOptions() {
      const cols = ["#3BDBDB", "#6CEBEB", "#C2FFFF", "#FFFFFF"];
      let xData = [];
      for (let i = 40; i > 0; i--) {
        var time = this.formatDate(new Date(new Date().getTime() - i * 20000));
        xData.push(time);
      }
      let yData = [];
      yData = [
        605, 588, 542, 422, 799, 288, 955, 451, 797, 712, 883, 255, 588, 542,
        422, 799, 288, 955, 451, 797, 605, 588, 542, 422, 799, 288, 955, 451,
        797, 712, 883, 255, 588, 542, 422, 799, 288, 955, 451, 797,
      ];
      let unit = "dB";
      this.jf_qushi_options = {
        grid: {
          top: "10%",
          left: "5%",
          right: "5%",
          bottom: "10%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#FFF",
          extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3)",
          textStyle: {
            color: "#000",
          },
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: [
          {
            type: "category",
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(255, 255, 255,.8)",
                //fontSize: 18
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#8c8c8c",
              },
            },
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
            splitLine: {
              show: false,
            },
            data: xData,
          },
        ],
        yAxis: [
          {
            name: "幅值(" + unit + ")",
            type: "value",
            min: 0,
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(255, 255, 255,.8)",
                //fontSize: 18
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#8c8c8c",
              },
            },
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#888888",
              },
            },
          },
        ],
        series: [
          {
            name: "最大放电量幅值",
            type: "line",
            symbolSize: 1,
            symbol: "circle",
            smooth: true,
            yAxisIndex: 0,
            showSymbol: false,
            lineStyle: {
              width: 3,
              color: cols[1],
            },
            itemStyle: {
              normal: {
                color: cols[1],
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0.4,
                      color: "rgba(194, 255, 255,0.1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(108, 235, 235,0.9)",
                    },
                  ]),
                },
              },
            },
            data: yData,
          },
        ],
      };
    },
    setWdQSOptions() {
      const cols = ["#3BDBDB", "#6CEBEB", "#C2FFFF", "#FFFFFF"];
      let xData = [];
      for (let i = 40; i > 0; i--) {
        var time = this.formatDate(new Date(new Date().getTime() - i * 20000));
        xData.push(time);
      }
      let yData = [];
      yData = [
        605, 588, 542, 422, 799, 288, 955, 451, 797, 712, 883, 255, 588, 542,
        422, 799, 288, 955, 451, 797, 605, 588, 542, 422, 799, 288, 955, 451,
        797, 712, 883, 255, 588, 542, 422, 799, 288, 955, 451, 797,
      ];
      let unit = "dB";
      this.wd_qushi_options = {
        grid: {
          top: "10%",
          left: "5%",
          right: "5%",
          bottom: "10%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#FFF",
          extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3)",
          textStyle: {
            color: "#000",
          },
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: [
          {
            type: "category",
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(255, 255, 255,.8)",
                //fontSize: 18
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#8c8c8c",
              },
            },
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
            splitLine: {
              show: false,
            },
            data: xData,
          },
        ],
        yAxis: [
          {
            name: "幅值(" + unit + ")",
            type: "value",
            min: 0,
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(255, 255, 255,.8)",
                //fontSize: 18
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#8c8c8c",
              },
            },
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#888888",
              },
            },
          },
        ],
        series: [
          {
            name: "最大放电量幅值",
            type: "line",
            symbolSize: 1,
            symbol: "circle",
            smooth: true,
            yAxisIndex: 0,
            showSymbol: false,
            lineStyle: {
              width: 3,
              color: cols[1],
            },
            itemStyle: {
              normal: {
                color: cols[1],
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0.4,
                      color: "rgba(194, 255, 255,0.1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(108, 235, 235,0.9)",
                    },
                  ]),
                },
              },
            },
            data: yData,
          },
        ],
      };
    },
    setZdQSOptions() {
      const cols = ["#3BDBDB", "#6CEBEB", "#C2FFFF", "#FFFFFF"];
      let xData = [];
      for (let i = 40; i > 0; i--) {
        var time = this.formatDate(new Date(new Date().getTime() - i * 20000));
        xData.push(time);
      }
      let yData = [];
      yData = [
        605, 588, 542, 422, 799, 288, 955, 451, 797, 712, 883, 255, 588, 542,
        422, 799, 288, 955, 451, 797, 605, 588, 542, 422, 799, 288, 955, 451,
        797, 712, 883, 255, 588, 542, 422, 799, 288, 955, 451, 797,
      ];
      let unit = "单位";
      this.zd_qushi_options = {
        grid: {
          top: "10%",
          left: "5%",
          right: "5%",
          bottom: "10%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#FFF",
          extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3)",
          textStyle: {
            color: "#000",
          },
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: [
          {
            type: "category",
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(255, 255, 255,.8)",
                //fontSize: 18
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#8c8c8c",
              },
            },
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
            splitLine: {
              show: false,
            },
            data: xData,
          },
        ],
        yAxis: [
          {
            name: "振幅(" + unit + ")",
            type: "value",
            min: 0,
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(255, 255, 255,.8)",
                //fontSize: 18
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#8c8c8c",
              },
            },
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#888888",
              },
            },
          },
        ],
        series: [
          {
            name: "振幅",
            type: "line",
            symbolSize: 1,
            symbol: "circle",
            smooth: true,
            yAxisIndex: 0,
            showSymbol: false,
            lineStyle: {
              width: 3,
              color: cols[1],
            },
            itemStyle: {
              normal: {
                color: cols[1],
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0.4,
                      color: "rgba(194, 255, 255,0.1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(108, 235, 235,0.9)",
                    },
                  ]),
                },
              },
            },
            data: yData,
          },
        ],
      };
    },
    setPrpdOptions() {
      let prpd = [
        9, 9, 9, 10, 10, 9, 10, 9, 10, 10, 9, 10, 10, 9, 9, 8, 8, 8, 8, 10, 8,
        9, 8, 8, 8, 8, 8, 9, 9, 9, 10, 9, 11, 11, 11, 11, 11, 11, 12, 10, 11, 9,
        10, 10, 10, 10, 9, 9, 9, 9, 10, 10, 12, 11, 10, 10, 9, 10, 10, 9, 9, 9,
        9, 10, 11, 12, 12, 10, 10, 10, 10, 8, 9, 9, 9, 10, 10, 10, 11, 10, 9,
        10, 11, 9, 9, 10, 9, 9, 9, 9, 10, 11, 10, 10, 9, 11, 9, 9, 10, 9, 9, 9,
        10, 10, 10, 9, 10, 12, 11, 8, 10, 8, 9, 8, 9, 12, 8, 8, 8, 8, 8, 8, 8,
        8, 8, 8, 8, 10, 8, 8, 8, 8, 8, 9, 10, 8, 8, 8, 8, 8, 9, 8, 8, 8, 8, 8,
        10, 10, 10, 12, 10, 10, 10, 10, 8, 8, 8, 8, 8, 9, 11, 11, 11, 12, 11,
        11, 10, 8, 8, 8, 8, 8, 10, 11, 11, 10, 11, 11, 10, 10, 9, 9, 9, 9, 9, 9,
        8, 8, 8, 9, 8, 8, 8, 8, 8, 9, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8,
        8, 11, 10, 11, 10, 11, 11, 10, 8, 8, 8, 8, 8, 10, 12, 10, 10, 10, 11,
        10, 10, 8, 8, 8, 8, 8, 12, 11, 11, 12, 11, 10, 10, 12, 8, 8, 8, 8, 8,
        11, 11, 12, 10, 10, 11, 12, 10, 8, 8, 8, 8, 8, 9, 8, 8, 8, 8, 8, 8, 8,
        8, 10, 8, 8, 8, 10, 9, 9, 9, 9, 8, 9, 10, 10, 10, 10, 10, 10, 8, 8, 8,
        8, 8, 8, 12, 10, 10, 10, 10, 10, 10, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8,
        8, 8, 8, 8, 8, 8, 9, 9, 9, 9, 9, 9, 8, 10, 10, 10, 10, 10, 10, 10, 8, 8,
        8, 8, 8, 10, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 9, 9, 9, 9, 8, 9, 8,
        10, 10, 10, 10, 10, 10, 10, 8, 8, 8, 8, 8, 10, 10, 10, 10, 10, 10, 11,
        10, 8, 8, 8, 8, 8, 10, 10, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 9, 8, 9, 8,
        8, 8, 10, 8, 9, 9, 9, 9, 9, 11, 11, 11, 11, 11, 11, 11, 10, 11, 9, 10,
        12, 11, 9, 10, 10, 10, 10, 10, 11, 9, 9, 10, 11, 10, 10, 9, 9, 8, 10, 9,
        8, 9, 12, 9, 10, 11, 11, 10, 8, 8, 8, 8, 8, 9, 8, 8, 8, 8, 8, 8, 8, 9,
        9, 10, 10, 9, 10, 9, 8, 8, 8, 8, 8, 9, 8, 8, 8, 8, 8, 8, 9, 9, 9, 9, 9,
        9, 12, 11, 11, 11, 11, 11, 11, 10, 10, 10, 10, 10, 11, 10, 10, 9, 9, 9,
        9, 12, 10, 11, 11, 10, 11, 9, 9, 10, 10, 10, 11, 10, 10, 10, 10, 10, 10,
        10, 11, 10, 11, 11, 11, 11, 11, 10, 10, 10, 10, 10, 9, 11, 10, 10, 11,
        11, 11, 10, 10, 11, 10, 10, 10, 10, 12, 11, 11, 11, 11, 10, 10, 10, 10,
        10, 10, 10, 10, 11, 10, 11, 10, 10, 12, 10, 10, 10, 10, 10, 10, 11, 11,
        11, 10, 10, 11, 10, 10, 11, 10, 11, 11, 11, 11, 10, 10, 10, 10, 10, 10,
        10, 10, 11, 11, 11, 11, 11, 10, 10, 10, 10, 10, 10, 10, 11, 11, 11, 11,
        11, 10, 10, 10, 10, 10, 10, 10, 11, 11, 11, 11, 11, 10, 10, 10, 10, 10,
        10, 10, 12, 12, 12, 12, 12, 12, 12, 11, 12, 10, 11, 10, 12, 11, 9, 10,
        10, 10, 10, 11, 13, 13, 13, 13, 13, 13, 12, 9, 9, 9, 11, 10, 13, 13, 13,
        13, 13, 13, 13, 9, 9, 10, 9, 9, 10, 13, 12, 12, 13, 12, 13, 13, 9, 11,
        10, 10, 9, 9, 13, 13, 13, 13, 13, 12, 12, 9, 9, 10, 10, 9, 12, 13, 13,
        13, 13, 12, 13, 13, 9, 9, 9, 9, 10, 13, 13, 13, 12, 13, 12, 13, 12, 10,
        9, 9, 9, 11, 13, 13, 13, 13, 13, 13, 13, 9, 10, 9, 10, 10, 9, 13, 13,
        13, 13, 12, 13, 13, 9, 9, 9, 10, 11, 10, 13, 13, 13, 13, 12, 13, 13, 11,
        9, 9, 9, 10, 11, 10, 9, 10, 9, 9, 9, 10, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9,
        9, 9, 10, 9, 10, 10, 10, 10, 10, 10, 11, 10, 11, 10, 10, 10, 10, 9, 9,
        9, 9, 10, 10, 10, 10, 10, 10, 11, 10, 9, 9, 9, 9, 9, 10, 10, 10, 10, 10,
        10, 10, 10, 9, 9, 9, 9, 10, 10, 10, 11, 10, 10, 10, 10, 10, 9, 9, 9, 9,
        10, 9, 10, 10, 10, 10, 10, 10, 9, 9, 9, 9, 9, 10, 10, 10, 10, 10, 10,
        10, 10, 9, 9, 9, 9, 9, 10, 10, 10, 10, 10, 11, 10, 10, 9, 9, 10, 9, 9,
        10, 10, 10, 10, 10, 10, 11, 10, 9, 9, 9, 10, 11, 8, 8, 8, 8, 8, 8, 9, 8,
        8, 8, 8, 8, 8, 9, 9, 9, 10, 10, 10, 10, 9, 8, 8, 8, 8, 9, 9, 9, 9, 10,
        10, 10, 9, 8, 8, 8, 8, 8, 8, 9, 9, 10, 10, 10, 10, 10, 9, 8, 8, 8, 8, 9,
        9, 9, 9, 10, 10, 10, 9, 8, 8, 8, 8, 10, 11, 10, 10, 9, 11, 10, 10, 10,
        10, 11, 10, 9, 9, 10, 10, 10, 9, 10, 9, 9, 8, 10, 9, 9, 10, 9, 8, 8, 8,
        9, 8, 9, 8, 8, 8, 8, 8, 8, 8, 9, 9, 10, 10, 10, 10, 9, 9, 8, 8, 8, 8, 9,
        9, 10, 9, 10, 9, 10, 9, 9, 8, 8, 8, 10, 10, 10, 10, 10, 11, 10, 12, 12,
        11, 10, 10, 11, 12, 9, 9, 10, 10, 10, 9, 10, 10, 10, 10, 10, 10, 10, 10,
        10, 10, 10, 10, 12, 11, 11, 10, 10, 10, 11, 11, 10, 10, 10, 10, 10, 10,
        12, 10, 10, 10, 10, 10, 11, 11, 11, 10, 13, 11, 13, 13, 13, 12, 13, 13,
        13, 13, 13, 13, 13, 13, 12, 13, 12, 12, 11, 12, 11, 12, 12, 11, 10, 10,
        13, 13, 13, 11, 13, 13, 11, 11, 13, 13, 13, 13, 13, 13, 13, 13, 12, 13,
        13, 13, 12, 12, 12, 13, 12, 12, 12, 12, 11, 10, 10, 11, 10, 13, 11, 11,
        10, 11, 13, 13, 13, 9, 9, 9, 9, 9, 10, 8, 8, 8, 8, 8, 8, 10, 8, 8, 8, 8,
        8, 9, 8, 8, 8, 8, 8, 10, 10, 11, 12, 11, 11, 11, 10, 9, 9, 9, 9, 9, 9,
        8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 9, 8, 9, 8, 8, 8, 10, 12, 11, 10,
        12, 12, 11, 12, 8, 8, 8, 8, 8, 10, 12, 11, 12, 10, 10, 11, 10, 8, 8, 8,
        8, 8, 10, 11, 11, 12, 12, 12, 10, 8, 8, 8, 8, 8, 8, 11, 11, 12, 12, 11,
        11, 10, 8, 8, 8, 8, 8, 8, 11, 11, 10, 10, 10, 11, 10, 8, 8, 8, 8, 8, 11,
        12, 10, 10, 10, 10, 10, 12, 8, 8, 8, 8, 8, 10, 9, 9, 9, 9, 9, 9, 10, 9,
        10, 10, 10, 10, 10, 10, 10, 10, 10, 11, 10, 11, 10, 9, 9, 9, 9, 9, 10,
        10, 10, 10, 11, 10, 10, 9, 9, 9, 9, 10, 10, 10, 10, 10, 10, 10, 10, 10,
        10, 9, 9, 9, 9, 9, 10, 10, 10, 10, 10, 10, 10, 9, 9, 10, 9, 12, 10, 12,
        11, 11, 11, 12, 10, 10, 10, 9, 10, 9, 9, 9, 9, 9, 9, 10, 9, 10, 10, 9,
        9, 10, 10, 10, 9, 9, 9, 9, 9, 10, 10, 9, 10, 10, 10, 10, 9, 9, 9, 9, 9,
        9, 10, 10, 9, 10, 10, 10, 9, 9, 9, 9, 9, 9, 10, 10, 9, 9, 9, 9, 9, 10,
        10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 11, 10, 11, 10, 11, 10, 11, 11,
        11, 11, 10, 10, 10, 11, 10, 10, 10, 10, 10, 10, 11, 11, 10, 11, 10, 10,
        10, 11, 11, 11, 10, 11, 10, 10, 11, 11, 10, 10, 11, 10, 10, 10, 10, 10,
        11, 10, 10, 13, 11, 12, 11, 11, 11, 12, 12, 12, 12, 11, 12, 12, 12, 11,
        11, 11, 10, 10, 10, 10, 11, 12, 12, 12, 12, 11, 10, 11, 11, 10, 11, 10,
        12, 10, 11, 11, 11, 11, 11, 11, 10, 10, 10, 10, 10, 11, 10, 10, 11, 12,
        12, 11, 11, 11, 10, 10, 10, 10, 10, 11, 11, 12, 11, 12, 11, 11, 11, 11,
        10, 10, 12, 10, 10, 11, 10, 11, 13, 11, 10, 12, 12, 11, 12, 11, 11, 10,
        10, 13, 11, 10, 11, 11, 10, 10, 10, 10, 10, 10, 12, 10, 10, 10, 9, 9, 9,
        9, 10, 9, 10, 9, 10, 10, 9, 12, 12, 12, 12, 12, 11, 12, 13, 12, 13, 13,
        13, 13, 10, 10, 10, 10, 10, 10, 10, 12, 11, 10, 11, 11, 10, 10, 10, 10,
        10, 11, 11, 9, 9, 9, 9, 9, 9, 9, 8, 9, 9, 10, 10, 13, 13, 13, 13, 13,
        13, 13, 13, 11, 13, 12, 12, 12, 12, 13, 13, 13, 13, 12, 13, 11, 10, 10,
        10, 10, 10, 10, 11, 11, 11, 10, 10, 11, 10, 11, 10, 11, 11, 11, 13, 12,
        8, 8, 8, 8, 9, 9, 11, 10, 10, 10, 10, 12, 11, 11, 11, 13, 11, 13, 11,
        12, 12, 13, 13, 12, 13, 12, 12, 12, 12, 13, 12, 12, 10, 10, 10, 10, 10,
        10, 11, 10, 11, 10, 10, 11, 10, 10, 10, 10, 10, 10, 11, 10, 9, 9, 9, 9,
        9, 10, 9, 10, 9, 9, 10, 10, 12, 11, 11, 12, 12, 11, 13, 13, 13, 12, 13,
        13, 12, 11, 11, 10, 10, 12, 10, 13, 11, 10, 10, 10, 11, 11, 10, 10, 9,
        9, 9, 10, 10, 9, 9, 11, 10, 10, 11, 12, 12, 10, 12, 11, 12, 13, 12, 13,
        13, 13, 13, 11, 10, 10, 10, 10, 10, 11, 10, 11, 10, 10, 11, 11, 10, 8,
        8, 8, 8, 8, 10, 8, 8, 8, 8, 8, 10, 11, 10, 10, 11, 11, 11, 10, 8, 8, 8,
        8, 8, 10, 11, 12, 11, 10, 11, 10, 9, 9, 9, 9, 9, 9, 11, 11, 11, 11, 11,
        11, 11, 9, 9, 9, 9, 9, 9, 11, 11, 12, 11, 11, 11, 11, 9, 9, 9, 9, 9, 10,
        11, 11, 11, 10, 10, 11, 11, 9, 9, 9, 9, 9, 11, 11, 11, 12, 11, 11, 11,
        11, 9, 9, 9, 9, 9, 11, 11, 10, 10, 11, 11, 11, 9, 9, 9, 9, 9, 9, 10, 11,
        11, 11, 11, 11, 11, 9, 9, 9, 9, 9, 9, 11, 10, 12, 11, 11, 11, 10, 9, 9,
        9, 9, 9, 9, 10, 11, 11, 11, 11, 12, 10, 8, 8, 8, 8, 8, 10, 8, 8, 8, 8,
        10, 8, 11, 10, 10, 10, 10, 11, 12, 12, 12, 10, 12, 13, 12, 9, 9, 9, 9,
        8, 10, 10, 10, 9, 10, 10, 9, 9, 10, 10, 10, 10, 10, 10, 10, 8, 8, 9, 8,
        9, 11, 10, 10, 10, 10, 10, 11, 12, 12, 12, 12, 12, 12, 12, 11, 12, 12,
        11, 12, 10, 9, 9, 9, 9, 9, 9, 10, 10, 10, 9, 10, 10, 10, 8, 9, 10, 10,
        10, 10, 9, 8, 8, 8, 8, 8, 9, 8, 10, 10, 8, 8, 13, 13, 13, 13, 12, 13,
        13, 10, 10, 10, 10, 13, 11, 12, 12, 12, 12, 12, 13, 13, 8, 8, 9, 8, 9,
        8, 9, 8, 9, 9, 8, 8, 10, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 10, 10, 10,
        10, 10, 10, 10, 8, 8, 8, 8, 8, 8, 11, 12, 10, 10, 10, 10, 10, 8, 8, 8,
        8, 8, 8, 10, 10, 10, 10, 10, 10, 11, 8, 8, 8, 8, 8, 11, 8, 8, 8, 8, 8,
        8, 10, 8, 8, 8, 8, 8, 8, 10, 10, 10, 10, 9, 9, 8, 8, 8, 8, 8, 8, 10, 10,
        9, 10, 10, 10, 10, 8, 8, 8, 8, 8, 8, 10, 10, 10, 9, 9, 10, 9, 8, 8, 8,
        8, 8, 10, 9, 9, 9, 9, 9, 10, 9, 8, 8, 8, 8, 8, 10, 10, 10, 10, 10, 10,
        11, 11, 10, 10, 10, 10, 10, 10, 11, 10, 11, 10, 11, 12, 11, 11, 11, 11,
        11, 10, 10, 10, 11, 11, 11, 11, 12, 11, 11, 11, 11, 10, 10, 10, 10, 10,
        12, 12, 11, 11, 11, 11, 11, 10, 10, 10, 10, 10, 10, 10, 12, 11, 12, 11,
        11, 10, 11, 11, 10, 11, 10, 10, 10, 10, 10, 10, 10, 11, 11, 10,
      ];
      let array = prpd;
      // 处理数组
      const maxIndex = 360; // 我们希望索引范围是 0-359

      const elementsPerGroup = 128;
      const step = maxIndex / elementsPerGroup; // 计算每个元素的步长
      let result = [];
      for (let i = 0; i < array.length; i += elementsPerGroup) {
        // 切割每一组128个元素
        const chunk = array
          .slice(i, i + elementsPerGroup)
          .map((value, index) => {
            // 计算新的索引，将索引平分到0-359范围内
            const newIndex = Math.floor(index * step);
            return [newIndex, value];
          });

        // 合并每一组的数据到结果数组中
        result = result.concat(chunk);
      }
      let xAxisData = [];
      for (let index = 0; index < 359; index++) {
        xAxisData.push(index);
      }
      this.prpdOptions = {
        grid: {
          show: true,
          left: "20%",
          top: "15%",
          right: "10%",
          bottom: "15%",
          backgroundColor: "rgba(255, 255, 67, 0.1)",
          borderColor: "rgba(96, 67, 67, 1)",
        },
        title: {
          top: 0,
          left: "center", // left的值为'left', 'center', 'right'
          text: "prpd图谱",
          // 标题文本样式
          textStyle: {
            color: "#fff", // 颜色
            fontStyle: "normal", // 字体风格,'normal','italic','oblique'
            fontWeight: "200", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
            fontFamily: "sans-serif", //字体系列
            fontSize: 14, //字体大小
          },
        },
        xAxis: {
          type: "category",
          minorTick: {
            show: false,
          },
          minorSplitLine: {
            show: false,
          },
          axisLabel: {
            align: "center",
          },
          data: xAxisData,
        },
        yAxis: {
          name: "幅\n值\n（mV）",
          type: "value",
          nameLocation: "bottom",
          min: 0,
          max: 255,
        },
        tooltip: {
          trigger: "axis",
          extraCssText: 'max-width:10px; white-space:pre-wrap',
          formatter: function (params) {
            let arr = []
            params.forEach(v => {
              arr.push(v.data[1])
            });
            return params[0].data[0]+":<br>["+arr.toString()+"]"
        },
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        series: [
          {
            name: "scatter",
            type: "scatter",
            symbolSize: 1,
            color: "rgba(255,201,14)",
            data: result,
          },
        ],
      };
    },
    formatDate(val) {
      return this.getTimesInterval(val);
    },
    getTimesInterval(val) {
      let _this = this;
      let year = val.getFullYear(); //获取当前时间的年份
      let month = val.getMonth() + 1; //获取当前时间的月份
      let day = val.getDate(); //获取当前时间的天数
      let hours = val.getHours(); //获取当前时间的小时
      let minutes = val.getMinutes(); //获取当前时间的分数
      let seconds = val.getSeconds(); //获取当前时间的秒数
      //当小于 10 的是时候，在前面加 0
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      //拼接格式化当前时间
      _this.times =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      return _this.times;
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style scoped lang="scss">
.fault-content-1 {
  width: 95%;
  margin: 0 auto;
  height: calc(100vh - 150px);
  color: rgb(113, 203, 255);

  .display-flex {
    display: flex;
  }
  .texts {
    margin-left: 10px;
    color: aliceblue;
    font-size: 24px;
  }
  .textsub {
    margin-left: 20px;
    color: rgba(255, 235, 59, 1);
    font-size: 18px;
    font-weight: 700;
  }
  .content-text {
    flex-wrap: wrap;
    font-size: 16px;
    color: #fff;
    margin: 10px;
  }
  .subt {
    margin-left: 20px;
    color: rgba(194, 184, 92, 1);
    font-size: 18px;
  }
  .result {
    background-image: linear-gradient(#ffffff22, #64c4fc88);
    color: rgba(255, 81, 81, 1);
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
  }
  .content-1 {
    display: grid;
    grid-template-columns: 1fr 1.2fr 1fr;
    .left {
      margin: 5px;
      border: 1px solid rgb(82, 188, 250);
      .chart {
        width: 50%;
        height: calc(14vh);
        border: 1px solid rgb(28, 69, 131);
      }
      .chart-qushi {
        width: 100%;
        height: calc(14.5vh);
      }
    }
    .center {
      display: grid;
      grid-template-rows: 1fr 1fr;
      .c-1 {
        margin: 5px;
        height: 98%;
        display: flex;
        justify-content: center;
        .responsive-image {
          width: 60%; /* 图片宽度自适应父容器 */
          height: auto; /* 图片高度自适应宽度 */
        }
        .status {
          position: relative;
          width: 150px;
          height: 53px;
          border-radius: 10px;
          background-color: rgba(159, 0, 0, 0.46);
          display: flex;
          .circle {
            width: 20px; /* 圆的宽度 */
            height: 20px; /* 圆的高度 */
            margin: 16px 0 0 10px;
            background-color: rgba(183, 28, 28, 1); /* 圆的背景颜色 */
            border-radius: 50%; /* 使边角圆滑，形成圆形 */
          }
          .text {
            color: #fff;
            font-size: 24px;
            margin: 8px 0 0 10px;
          }
        }
        @keyframes flash {
          0% {
            background-color: rgba(254, 91, 3, 0.67);
          } /* 红色 */
          50% {
            background-color: rgba(159, 0, 0, 0.46);
          } /* 绿色 */
          100% {
            background-color: rgba(254, 91, 3, 0.67);
          } /* 蓝色 */
        }
        .position1 {
          position: absolute;
          top: 10%;
          right: 40%;
          animation: flash 1s infinite;
        }
        .position2 {
          position: absolute;
          top: 15%;
          left: 38%;
        }
        .position3 {
          position: absolute;
          top: 21%;
          right: 34%;
        }
      }
      .c-2 {
        margin: 5px;
        height: 98%;
        border: 1px solid rgb(82, 188, 250);
        .chart-qushi {
          width: 100%;
          height: calc(14.5vh);
        }
      }
    }
    .right {
      display: grid;
      grid-template-rows: 1fr 1.2fr;
      .r-1 {
        margin: 5px;
        height: 98%;
        border: 1px solid rgb(82, 188, 250);
        .responsive-image1 {
          margin-top: 30px;
          margin-left: 30px;
          width: 210px;
          height: 235px;
          height: auto; /* 图片高度自适应宽度 */
        }
      }
      .r-2 {
        margin: 5px;
        height: 98%;
        border: 1px solid rgb(82, 188, 250);
      }
    }
  }
}
</style>